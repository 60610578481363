
import { MenuModule } from "@/store/modules/menu";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// import { Route } from "vue-router";
// import { UserModule } from "@/store/modules/user";
// import { computedStrLen } from "@/utils/util";

@Component({
  name: "Nav",
})
export default class extends Vue {
  // computedStrLen: any = computedStrLen;
  // get userInfo() {
  //   return UserModule.userInfo;
  // }

  @Prop({ default: 6 })
  flag: any;

  hide = false;
  breadcrumbs: any[] = [];

  @Watch("flag", { immediate: true })
  onFlagChange(val: any) {
    this.hide = val !== 6;
  }

  // 获取当前导航
  @Watch("$store.state.Menu.menuItemKey") // { immediate: true }
  onMenuChange() {
    // console.log("nav-update", MenuModule.menuItemKey, MenuModule.menuListMap);
    const menuItem: any = MenuModule.menuListMap[MenuModule.menuItemKey];
    if (!menuItem?.idx) {
      return; // 未找到对应的菜单
    }
    // 使用/拆分menuItem.idx为数组
    const idxArr = menuItem.idx.split("/");
    let reslist: any = [];
    for (let i = 0; i < idxArr.length; i++) {
      // const idx = idxArr.slice(0, i + 1).join("/");
      // reslist = [...reslist, MenuModule.menuListMap[idx]];
      const item = MenuModule.menuListMap[idxArr[i]];
      if (item) {
        reslist.push(item);
      }
    }
    this.breadcrumbs = reslist;
  }

  // 点击面包屑
  clickBreadcrumbItem(item: any) {
    // 点击在目前继承iframe的架构体系下是没有意义的，所以暂时取消面包屑的点击事件
    // 如果openType===3 iframe嵌入替换store中的clickItem  其他走项目内的路由导航
    // if (item.openType === 3) {
    //   this.$router.push({ name: "ipath", query: { p: item.path0 } });
    // } else {
    //   this.$router.push(item.path);
    // }
  }
}
