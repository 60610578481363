// 应用路由
const routers = [
  {
    path: "demo",
    name: "demo",
    meta: { hidden: false },
    component: () => import("@/views/demo.vue"),
  },
  {
    // 任务管理
    path: "/taskList",
    name: "taskList",
    meta: { hidden: false },
    component: () => import("@/views/TaskList/index.vue"),
  },
  {
    // 任务结果管理
    path: "/taskResult",
    name: "taskResult",
    meta: { hidden: false },
    component: () => import("@/views/TaskResult/index.vue"),
  },
  {
    // 执行结果
    path: "/executionResult",
    name: "executionResult",
    meta: { hidden: false },
    component: () => import("@/views/ExecutionResult/index.vue"),
  },
  {
    // 考勤明细
    path: "/attendanceList",
    name: "attendanceList",
    meta: { hidden: false },
    component: () => import("@/views/AttendanceList/index.vue"),
  },
  {
    path: "/tenantContractAuth",
    name: "tenantContractAuth",
    meta: { hidden: false },
    component: () => import("@/views/TenantContractAuth/index.vue"),
  },
  // 模板列表
  {
    path: "/templateConfigList",
    name: "TemplateConfigList",
    meta: { hidden: false },
    component: () => import("@/views/TemplateConfig/List.vue"),
  },
  // 模板详情
  {
    path: "/templateConfigDetail",
    name: "TemplateConfigDetail",
    meta: { hidden: false },
    component: () => import("@/views/TemplateConfig/Detail.vue"),
  },

  {
    path: "/person",
    name: "person",
    meta: { hidden: false },
    component: () => import("@/views/fdm/person/index.vue"),
  },
  {
    path: "/project",
    name: "project",
    meta: { hidden: false },
    component: () => import("@/views/fdm/project/index.vue"),
  },
  {
    path: "/tenant",
    name: "tenant",
    meta: { hidden: false },
    component: () => import("@/views/fdm/tenant/index.vue"),
  },
  {
    path: "/tenantManage",
    name: "tenantManage",
    meta: { hidden: false },
    component: () => import("@/views/fdm/tenantManage/index.vue"),
  },
];
export default routers;
