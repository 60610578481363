
import { SigninModule } from "@/store/modules/signin";
import { UserModule } from "@/store/modules/user";
import { MenuModule } from "@/store/modules/menu";
import { Empty } from "ant-design-vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "App",
})
export default class extends Vue {
  simpleImage: any = (Empty as any).PRESENTED_IMAGE_SIMPLE; // Empty.PRESENTED_IMAGE_SIMPLE
  get userInfo() {
    return UserModule.userInfo;
  }

  locale = zhCN;

  // iframe page signature number => isn 页面签名
  isn = "";

  mounted() {
    // 通过window.location.search获取父节点给予的参数
    this.isn = new URLSearchParams(window.location.search).get("isn") || "";
    // 浏览器 切换回当前 tab 网页，刷新
    document.addEventListener("visibilitychange", async () => {
      const pgid = localStorage.getItem("page_id");
      if (document.visibilityState !== "hidden" && pgid) {
        if (pgid !== (this.userInfo as any).page_id) {
          location.reload();
        }
      }
    });
    //======================================================================
    // 浏览器关闭，清除本地存储
    window.addEventListener("beforeunload", (e) => this.clearLocalStrage());
    //======================================================================
    // iframe页面绑定事件，监听子页面刷新事件
    window.addEventListener("message", (e) => {
      // data: 消息, origin: 站点, source: 对象引用
      let data = (e as any)?.data;
      if (typeof data !== "string") {
        return; // 消息类型无法处理，忽略
      }
      // 输出子页面的消息
      console.log("window-child-message:", data);
      if (!data.startsWith("SWIFT:")) {
        return; // 消息并非SWIFT框架消息，忽略
      }
      data = data.substring(6); // 截取SWIFT:后面的消息
      const ekey = data.substring(0, data.indexOf(":"));
      if (!ekey) {
        return; // 消息类型无法处理，忽略
      }
      data = data.substring(ekey.length + 1); // 截取事件类型后面的消息
      // let pkey = this.evkeys[ekey] ? this.evkeys[ekey].substring(0, 1) : "1";
      // pkey = pkey === "1" ? "0" : "1";
      // this.evkeys[ekey] = pkey + data; // 放入消息监控器中，通过watch触发事件

      // 直接触发消息内容, Watch监控虽然结构化，但是性能不好
      this.onEventByKey(ekey, data, e);
    });
    //======================================================================
    // 监听请求错误事件，如果是401错误，通知上级页面重新登录
    window.addEventListener("plusrequesterror", (e) => {
      // detail: 请求错误详情
      if ((e as any).detail?.message?.indexOf("401") >= 0) {
        console.log("plusrequesterror", e);
        if (window !== window.parent && window.parent?.postMessage && (UserModule.userInfo as any).page_id) {
          // 应用已经登录，但是token失效，通知上级页面当前应用从新登录行为
          // 这里可能还是有问题，旧的token和新的token冲突导致不可用问题， 最好指定origin, 暂时使用全局
          // SWIFT: 作文向上级页面发送消息的前缀
          window.parent.postMessage("SWIFT:relogin:" + (UserModule.userInfo as any).page_id, "*");
        }
      }
    });
  }

  //======================================================================
  // 清除本地存储
  clearLocalStrage() {
    localStorage.setItem("page_id", "");
    localStorage.setItem("dir-user-detail-depts-open-nodes", "");
  }

  // 监听路由地址变化
  @Watch("$route")
  onRouteChange() {
    // SWIFT: 作文向上级页面发送消息的前缀
    if (window !== window.parent && window.parent?.postMessage) {
      // if (window.parent?.postMessage) {
      // console.log("window-app.vue:", this.$route.path);
      // 通知上级页面路由变化
      // const { path, name, hash, query, params, fullPath, meta } = this.$route;
      const { fullPath, meta } = this.$route;
      const data = { isn: this.isn, path: fullPath, title: meta?.title || "" };
      window.parent.postMessage("SWIFT:cc$route:" + JSON.stringify(data), "*");
    }
  }

  //=======================================================================================
  // 触发消息事件
  onEventByKey(ekey: string, data: string, event: any) {
    if (ekey === "relogin") {
      // 子页面令牌以过期，需要主页面验证登录信息
      this.onRegloinHandle(data);
    } else if (ekey === "cc$route") {
      // 子页面路由变化，需要主页面路由变化
      const origin = event?.origin || "";
      const params = { ...JSON.parse(data), origin };
      // 放入监控器中，其他系统通过监控器触发事件
      // console.log("cc$route", params.nkey, params);
      MenuModule.SET_CHILD_ROUTES(params);
    }
    // else do nothing
  }

  // @Watch("evkey['relogin']", { deep: true })
  onRegloinHandle(value: string) {
    const data = value.substring(0, value.indexOf("-"));
    // 当前系统登录信息
    const pgid = (this.userInfo as any).page_id;
    if (pgid?.startsWith(data)) {
      // console.log("window-parent-relogin-" + pgid)
      // 同一个令牌授权的子页面，子页面过期，需要立即验证登录状态
      UserModule.setCanGetUser(true);
      SigninModule.login(); // 检查登录状态
    } else {
      console.log("window-parent-message: nologin," + (pgid || "no_page_id"));
      // 已经如果没有登录信息，或者不是同一个站点信息，暂时不处理
      // location.reload()
    }
  }
}
