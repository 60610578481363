// 默认菜单，没有从后台获取到使用的备用
const menus: any = [];
//   {
//     icon: null,
//     idx: "/a1",
//     meta: {},
//     name: "Demo",
//     children: [
//       {
//         disable: false,
//         icon: null,
//         idx: "/a1/c1",
//         meta: {},
//         name: "Demo内链",
//         openType: 1,
//         path: "/demo",
//         path0: "/demo",
//       },
//       {
//         disable: false,
//         icon: null,
//         idx: "/a1/c2",
//         meta: {},
//         name: "Demo外链",
//         openType: 1,
//         isLink: true,
//         path: "https://sls.dev1.sims-cn.com/logList?kin=ten",
//         path0: "/logList",
//       },
//     ],
//   },
// ];
export default menus;
