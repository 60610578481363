
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";

@Component({
  name: "Content",
})
export default class extends Vue {
  layout!: string;

  @Watch("$route", { immediate: true })
  onRouteChange(route: Route) {
    // console.log("onRouteChange:", route);
    this.layout = (route.matched[route.matched.length - 1] as any)?.meta?.layout || "";
    this.clearScoll();
  }

  // 清除滚动条状态
  clearScoll() {
    const ref = this.$refs.ref_content as any;
    if (ref) {
      ref.scrollTop = 0;
    }
  }
}
